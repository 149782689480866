import { Events } from '@prisma/client';
import { QueryClient } from '@tanstack/react-query';
import { UserWithNylasAccount } from '~/types/calendar/nylasProviders';

export const UpdateEventsToUserCalendar = (queryClient: QueryClient, events: Events[]) => {
  const currentUser = queryClient.getQueryData(['user']) as UserWithNylasAccount;
  
  if (currentUser) {
    const updatedCalendars = currentUser.nylas_account.nylas_calendars.map(calendar => {
      const calendarEvents = events.filter(e => e.nylasCalendarId === calendar.id);
      return {
        ...calendar,
        Events: [...calendar.Events, ...calendarEvents]
      };
    });

    const updatedUser = {
      ...currentUser,
      nylas_account: {
        ...currentUser.nylas_account,
        nylas_calendars: updatedCalendars
      }
    };
    console.log(updatedUser);
    queryClient.setQueryData(['user'], updatedUser);
  }
};
