import { ComponentProps, useContext } from "react";

import { Input } from "~/components/v2/Input";

import { GuestSelectContext } from "../guest-select-context";

interface GuestSelectInputProps extends ComponentProps<typeof Input> {}

export const GuestSelectInput = ({ ...props }: GuestSelectInputProps) => {
  const { searchValue, setSearchValue, onKeyDown } =
    useContext(GuestSelectContext);

  return (
    <Input
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      className="placeholder:text-typography-low-contrast-text"
      autoComplete="off"
      onKeyDown={onKeyDown}
      {...props}
    />
  );
};
