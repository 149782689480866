import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useContext, useState } from "react";

import { Button } from "~/components/v2/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { useScheduleInterviews } from "~/hooks/interviews/useScheduleInterviews";
import { useToast } from "~/hooks/useToast";
import { FormData } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/components/schedule-interview-form/schedule-interview-form.types";
import { ScheduleInterviewDrawerProvider } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/schedule-interview-drawer-context";
import { parseFormData } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/schedule-interview-drawer.utils";
import { ScheduleInterviewContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview-context";
import { errorHandler } from "~/utils/error";
import { ScheduleInterviewForm } from "./components/schedule-interview-form";
import { ScheduleInterviewHeader } from "./components/schedule-interview-header";
import { ScheduleInterviewDrawerProps } from "./schedule-interview-drawer.types";

export const ScheduleInterviewDrawer = ({
  stages,
  open,
  onOpenChange,
}: ScheduleInterviewDrawerProps) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { applicationId, stageId, interviewers, calendars, defaultTimezone } =
    useContext(ScheduleInterviewContext);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { mutateAsync: createInterviews, isPending: isLoading } =
    useScheduleInterviews({
      onSuccess: () => {
        onOpenChange(false);
        setIsSubmit(false);
        queryClient.invalidateQueries({
          queryKey: ["candidate-application", applicationId],
        });
      },
      onError: e => {
        errorHandler(toast, e as AxiosError<any>);
      },
    });

  const handleSubmit = async (data: FormData) => {
    const parsedInterviews = data.interviews.map(interviewForm => {
      return parseFormData(interviewForm, calendars);
    });

    await createInterviews({
      applicationId,
      data: [...parsedInterviews],
    });
  };

  const handleErrorSubmit = (error: any) => {
    setIsSubmit(false);
  };

  return (
    <ScheduleInterviewDrawerProvider
      stages={stages}
      interviewers={interviewers!}
      initialStageId={stageId}
      calendars={calendars}
      defaultTimezone={defaultTimezone}
    >
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent asChild onResize={() => false}>
          <div>
            <DrawerHeader className="text-center">
              <h1 className="text-base font-semibold text-typography-high-contrast">
                Schedule Interview
              </h1>
            </DrawerHeader>

            <div className="h-full p-4 space-y-2 overflow-y-auto">
              <ScheduleInterviewHeader />

              <ScheduleInterviewForm
                isSubmit={isSubmit}
                onSubmit={(data: FormData) => {
                  handleSubmit(data);
                }}
                onErrorSubmit={handleErrorSubmit}
              />
            </div>

            <DrawerFooter className="justify-end">
              <DrawerClose asChild>
                <Button variant="outline">Back</Button>
              </DrawerClose>
              <Button
                aria-label="Confirm"
                className="min-w-20"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => setIsSubmit(true)}
              >
                Save
              </Button>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </ScheduleInterviewDrawerProvider>
  );
};
