import { timezones } from "~/helper/timezones";

export const formatDateInterviewEvent = (date: Date) => {
  if (!date) return "";

  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(date);
};

export const formatTimeInterviewEvent = (date: Date) => {
  if (!date) return "";

  return new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
  }).format(date);
};

export const formatTimezone = (timezone: string) => {
  return timezones().find(tz => tz.timeZone === timezone)?.formattedText;
};
