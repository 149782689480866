import { MilestoneType } from "@prisma/client";

export const MILESTONE_COLOR: { [key in MilestoneType]: string } = {
  SOURCING: "#E5C9FB",
  APPLICATION_REVIEW: "#C9D5FB",
  PRE_SCREEN: "#BDE9FD",
  CUSTOM: "#C8FBF6",
  OFFER: "#DFFFB1",
  HIRED: "#A6FBD0",
};
