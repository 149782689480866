import { PlusIcon } from "@radix-ui/react-icons";
import { useContext } from "react";
import { Button } from "~/components/v2/Button";
import { GuestSelectContext } from "../../../guest-select-context";
import { CandidateType } from "~/types/jobseeker";

export const AddGuest = () => {
  const { searchValue, addSelected } = useContext(GuestSelectContext);

  return (
    <Button
      variant="ghost"
      className="flex gap-2"
      type="button"
      onClick={() =>
        addSelected({
          email: searchValue,
          type: CandidateType.GUEST
        })
      }
    >
      <span className="text-sm font-semibold text-typography-color">
        Add {searchValue}
      </span>
      <PlusIcon className="size-4 text-typography-color" />
    </Button>
  );
};
