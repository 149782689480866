import { QueryClient } from "@tanstack/react-query";
import { UserWithNylasAccount } from "~/types/calendar/nylasProviders";

export const UpdateNylasAccountSettings = (
  queryClient: QueryClient,
  timezone: string,
  hourDisplay: number,
) => {
  const currentUser = queryClient.getQueryData([
    "user",
  ]) as UserWithNylasAccount;
  if (currentUser) {
    const updatedUser = {
      ...currentUser,
      nylas_account: {
        ...currentUser.nylas_account,
        timezone,
        hourDisplay,
      },
    };
    queryClient.setQueryData(["user"], updatedUser);
  }
};
