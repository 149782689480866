import { useContext, useEffect, useRef } from "react";

import { cn } from "~/utils/cn";

import { GuestSelectContext } from "../../guest-select-context";
import { AddGuest } from "./components/add-guest";
import { NoSuggestions } from "./components/no-suggestions";
import { SuggestionItem } from "./components/suggestion-item";

interface GuestSelectListSuggestionsProps {
  emptyMessage: string;
}

export const GuestSelectListSuggestions = ({
  emptyMessage,
}: GuestSelectListSuggestionsProps) => {
  const {
    suggestions,
    showSuggestions,
    showEmptyState,
    showAddGuest,
    activeIndex,
    setActiveIndex,
  } = useContext(GuestSelectContext);

  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      const activeItem = listElement.children[activeIndex] as HTMLElement;
      if (activeItem) {
        activeItem.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [activeIndex]);

  if (!showSuggestions && !showEmptyState && !showAddGuest) {
    return null;
  }

  return (
    <ul
      ref={listRef}
      className={cn(
        "absolute z-10 mt-2 max-h-60 w-full overflow-y-auto rounded-md border border-borders bg-white p-2 shadow",
        {
          "flex items-center justify-center p-8":
            showEmptyState || showAddGuest,
        },
      )}
    >
      {showSuggestions &&
        suggestions.map((suggestion, index) => (
          <SuggestionItem
            key={suggestion.email}
            suggestion={suggestion}
            itemIndex={index}
            isActive={activeIndex === index}
            onActive={(index) => setActiveIndex(index)}
          />
        ))}

      {showEmptyState && <NoSuggestions message={emptyMessage} />}
      {showAddGuest && <AddGuest />}
    </ul>
  );
};
