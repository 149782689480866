import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchUser } from "~/queries/user/fetchUser";
import { UpdateEventsToUserCalendar } from "~/queries/calendar/updateEventsToUserCalendar";
import { AddEventToUserCalendar } from "~/queries/calendar/addEventToUserCalendar";
import { UpdateEventToUserCalendar } from "~/queries/calendar/updateEventToUserCalendar";
import { UpdateNylasAccountSettings } from "~/queries/calendar/updateNylasAccountSettings";
import { Events } from "@prisma/client";

export const useUserWithNylasAccount = (email: string) => {
  const queryClient = useQueryClient();

  const {
    data: user,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["user"],
    queryFn: () => fetchUser(email),
    enabled: !!email,
  });

  const updateEventsToUserCalendar = (events: Events[]) => {
    return UpdateEventsToUserCalendar(queryClient, events);
  };

  const addEventToUserCalendar = (event: Events) => {
    return AddEventToUserCalendar(queryClient, event);
  };

  const updateEventToUserCalendar = (event: Events) => {
    return UpdateEventToUserCalendar(queryClient, event);
  };

  const updateNylasAccountSettings = (
    timezone: string,
    hourDisplay: number,
  ) => {
    return UpdateNylasAccountSettings(queryClient, timezone, hourDisplay);
  };

  return {
    user,
    isLoading,
    refetch,
    updateEventsToUserCalendar,
    addEventToUserCalendar,
    updateEventToUserCalendar,
    updateNylasAccountSettings,
  };
};
