import axios from "~/services/axios.service";
import { InterviewsBody } from "~/types/interviews";

export interface ScheduleInterviewsProps {
  applicationId: number;
  data: InterviewsBody;
}

export interface ScheduleInterviewsResponse {
  completed: boolean;
}

export const scheduleInterviews = async ({
  applicationId,
  data,
}: ScheduleInterviewsProps): Promise<ScheduleInterviewsResponse> =>
  await axios
    .post(`/api/interviews/${applicationId}`, data)
    .then(response => response.data as ScheduleInterviewsResponse)
    .catch(e => e.message);
