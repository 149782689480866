import { ChevronDownIcon } from "@radix-ui/react-icons";
import Image from "next/image";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  FaArrowRight,
  FaChevronDown,
  FaChevronUp,
  FaPlus,
  FaXmark,
} from "react-icons/fa6";

import { Button } from "~/components/v2/Button";
import { Combobox } from "~/components/v2/Combobox";
import { DatePicker } from "~/components/v2/DatePicker";
import { Divider } from "~/components/v2/Divider";
import { Editor } from "~/components/v2/Editor";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/v2/Form";
import { Input } from "~/components/v2/Input";
import { InputCopy } from "~/components/v2/InputCopy";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/v2/Select";
import { Switch } from "~/components/v2/Switch";
import { timezones } from "~/helper/timezones";
import { StageSelect } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/components/interview-card/components/stage-select";
import { MILESTONE_INTERVIEW_COLOR } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/components/interview-card/interview-card.utils";
import { ScheduleInterviewDrawerContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/schedule-interview-drawer-context";
import { StageData } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/schedule-interview-drawer.types";
import { cn } from "~/utils/cn";

interface InterviewCardProps {
  index: number;
  onDelete: (index: number) => void;
}

export const InterviewCard = ({ index, onDelete }: InterviewCardProps) => {
  const { calendars, stages, interviewers } = useContext(
    ScheduleInterviewDrawerContext,
  );

  const form = useFormContext();

  const [collapsed, setCollapsed] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedStage, setSelectedStage] = useState<StageData>(() => {
    const stageId = form.watch(`interviews.${index}.stageId`);

    return stages.find(stage => stage!.id === stageId)!;
  });

  const handleToogleCollapse = () => setCollapsed(!collapsed);

  const availableStages = () => {
    const stageIds = form
      .watch("interviews")
      ?.map((interview: any) => Number(interview.stageId));

    if (!stageIds) return stages;

    return stages.filter(
      stage => !stageIds.includes(stage!.id) || stage!.id === selectedStage?.id,
    );
  };

  useEffect(() => {
    form.setValue(`interviews.${index}.stageId`, selectedStage?.id!);
  }, [selectedStage]);

  return (
    <div className={"relative flex flex-col rounded-xl border border-borders"}>
      {collapsed && (
        <div
          className={cn(
            "absolute bottom-0 left-0 top-0 z-10 w-2 rounded-l-lg",
            `${MILESTONE_INTERVIEW_COLOR[selectedStage?.milestone?.milestoneType!]}`,
          )}
        />
      )}
      <div className="flex w-full items-center justify-between px-3 py-1.5">
        <input name={`interviews.${index}.stageId`} type="hidden" />
        <StageSelect
          initialStage={selectedStage}
          stages={availableStages()}
          onSelect={setSelectedStage}
        />
        <div>
          <Button
            variant="secondary-ghost"
            className="hover:bg-destructive-background"
            type="button"
            size="icon"
            onClick={() => onDelete(index)}
          >
            <FaXmark className="text-base" />
          </Button>
          <Button
            variant="secondary-ghost"
            size="icon"
            type="button"
            onClick={handleToogleCollapse}
          >
            {collapsed ? (
              <FaChevronUp className="text-base" />
            ) : (
              <FaChevronDown className="text-base" />
            )}
          </Button>
        </div>
      </div>
      {collapsed && (
        <div className="flex h-full w-full flex-col gap-4 border-t py-4">
          <FormField
            control={form.control}
            name={`interviews.${index}.calendar`}
            render={({ field }) => (
              <FormItem
                className="space-y-1 pl-5 pr-3"
                data-testid="selectCalendar"
              >
                <FormLabel className="text-sm font-semibold text-typography-high-contrast">
                  Calendar*
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="h-8 w-full rounded-lg">
                      <SelectValue placeholder="Select a calendar" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="bg-white">
                    {calendars?.map(calendar => (
                      <SelectItem
                        value={calendar.calendar_id}
                        key={calendar.calendar_id}
                      >
                        {calendar.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <Divider />
          <div className="flex flex-col gap-2 pl-5 pr-3">
            <FormField
              control={form.control}
              name={`interviews.${index}.date`}
              render={({ field }) => (
                <FormItem
                  className="flex w-full flex-col"
                  data-testid="date-input"
                >
                  <FormControl>
                    <DatePicker
                      date={field.value}
                      onSelect={field.onChange}
                      className="border-borders-border h-8 w-full rounded-lg border"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="flex w-full items-end justify-between">
              <FormField
                control={form.control}
                name={`interviews.${index}.startTime`}
                render={({ field }) => (
                  <FormItem
                    data-testid="time-input"
                    className="flex w-full flex-col"
                  >
                    <FormControl>
                      <Input
                        type="time"
                        placeholder=""
                        className="h-8 rounded-lg"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <span className="mx-3 mb-2 flex align-bottom text-sm font-semibold">
                <FaArrowRight />
              </span>

              <FormField
                control={form.control}
                name={`interviews.${index}.endTime`}
                render={({ field }) => (
                  <FormItem
                    className="flex w-full flex-col"
                    data-testid="endtime-input"
                  >
                    <FormControl>
                      <Input
                        type="time"
                        placeholder=""
                        className="h-8 w-full"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name={`interviews.${index}.timezone`}
              render={({ field }) => (
                <FormItem
                  className="grid w-full items-center"
                  data-testid="timezone-input"
                >
                  <FormControl>
                    <Combobox
                      emptyTerm="timezone"
                      multi={false}
                      name="timezone"
                      options={
                        timezones()?.map(tz => ({
                          label: tz.formattedText,
                          value: tz.timeZone,
                        })) || []
                      }
                      searchByLabel
                      placeholder="Select a timezone"
                      searchPlaceholder="Search for a timezone"
                      setValue={form.setValue}
                      value={field.value}
                      className="border-borders-border h-8 rounded-lg"
                      icon={
                        <ChevronDownIcon className="h-4 w-4 text-icons-secondary" />
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`interviews.${index}.location`}
              render={({ field }) => (
                <FormItem data-testid="location-input">
                  <FormControl>
                    <InputCopy
                      className="h-8 rounded-lg"
                      placeholder="Add location"
                      hasIcon={!!form.getValues(`interviews.${index}.location`)}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center gap-2">
              <Image
                width={20}
                height={20}
                src={"/images/googlemeet.svg"}
                alt="link-logo"
                className="h-auto w-auto"
              />
              <span className="text-sm font-normal text-typography-low-contrast">
                {"Google Meet link"}
              </span>
              <FormField
                control={form.control}
                name={`interviews.${index}.createConference`}
                render={({ field }) => (
                  <FormControl data-testid="switch-conference">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                )}
              />
            </div>
          </div>
          <Divider />
          <FormField
            control={form.control}
            name={`interviews.${index}.interviewTeam`}
            render={({ field }) => (
              <FormItem
                className="grid w-full items-center pl-5 pr-3"
                data-testid="regardingcandidates-input"
              >
                <FormLabel className="text-sm font-semibold text-typography-high-contrast">
                  Interview Team
                </FormLabel>
                <FormControl>
                  <Combobox
                    emptyTerm=""
                    multi
                    name={`interviews.${index}.interviewTeam`}
                    options={
                      interviewers?.map(interviewer => ({
                        label: interviewer.label,
                        value: interviewer.value,
                      })) || []
                    }
                    searchByLabel
                    placeholder="Select an interview team member"
                    searchPlaceholder="Select an interview team member"
                    setValue={form.setValue}
                    value={field.value}
                    viewType="list"
                    dataType="candidate"
                    className="border-borders-border h-8 rounded-lg"
                    icon={
                      <ChevronDownIcon className="h-4 w-4 text-icons-secondary" />
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Divider />
          <div className="pl-5 pr-3">
            {showDetails ? (
              <FormField
                control={form.control}
                name={`interviews.${index}.notes`}
                render={({ field }) => (
                  <FormItem
                    className="grid w-full items-center"
                    data-testid="notes-input"
                  >
                    <FormLabel className="text-sm font-semibold text-typography-high-contrast">
                      Details
                    </FormLabel>
                    <FormControl>
                      <Editor
                        setValue={field.onChange}
                        contentClassName="h-[153px]"
                        autoFocus={false}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : (
              <Button
                variant="ghost"
                className="flex text-typography-color"
                onClick={() => setShowDetails(true)}
              >
                Add details
                <FaPlus className="ml-2" />
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
