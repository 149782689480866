import { Events } from '@prisma/client';
import { QueryClient } from '@tanstack/react-query';
import { UserWithNylasAccount } from '~/types/calendar/nylasProviders';

export const UpdateEventToUserCalendar = (queryClient: QueryClient, event: Events) => {
  const currentUser = queryClient.getQueryData(['user']) as UserWithNylasAccount;
  
  if (currentUser) {
    const updatedCalendars = currentUser.nylas_account.nylas_calendars.map(calendar => {
      if (calendar.id === event.nylasCalendarId) {
        const filteredEvents = calendar.Events.filter(e => e.id !== event.id);
        return {
          ...calendar,
          Events: [...filteredEvents, event]
        };
      }
      return calendar;
    });

    const updatedUser = {
      ...currentUser,
      nylas_account: {
        ...currentUser.nylas_account,
        nylas_calendars: updatedCalendars
      }
    };

    queryClient.setQueryData(['user'], updatedUser);
  }
};
