import { forwardRef, useRef } from "react";
import { FaRegCopy } from "react-icons/fa6";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { Button } from "../Button";
import { Input, InputProps } from "../Input";

interface InputCopyProps extends InputProps {
  hasIcon?: boolean;
}

export const InputCopy = forwardRef<HTMLInputElement, InputCopyProps>(
  ({ hasIcon = true, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleCopy = () => {
      const inputValue = inputRef.current?.value ?? "";
      navigator.clipboard.writeText(inputValue);
    };

    return (
      <div className="relative flex items-center">
        <Input {...props} ref={ref || inputRef} />
        {hasIcon && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  className="absolute right-3 z-10 h-3 w-3 cursor-pointer"
                  size="icon"
                  variant="secondary-ghost"
                  type="button"
                  data-testid="copy-button"
                  onClick={handleCopy}
                >
                  <FaRegCopy className="h-3 w-3" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p className="z-50">Copy</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    );
  },
);
