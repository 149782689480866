import { useContext } from "react";

import { ScheduleInterviewDrawerContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/schedule-interview-drawer-context";

export const ScheduleInterviewHeader = () => {
  const { interviews } = useContext(ScheduleInterviewDrawerContext);

  const interviewDates =
    interviews
      ?.filter(interview => !!interview?.date)
      ?.map(interview => {
        return interview.date.toLocaleDateString("en-US", {
          weekday: "long",
          month: "short",
          day: "numeric",
          year: "numeric",
        }) as string;
      }) ?? [];

  return (
    <div className="space-y-2 text-typography-high-contrast">
      <span className="text-base font-semibold">Schedule Interview</span>
      {interviewDates?.length > 0 && (
        <ul className="flex flex-col text-sm">
          {interviewDates?.map(date => <li key={date}>{date}</li>)}
        </ul>
      )}
    </div>
  );
};
