import { NylasCalendar } from "@prisma/client";
import { FormInterviewData } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/components/schedule-interview-form/schedule-interview-form.types";
import adjustWhenTime from "~/utils/calendar/adjustWhenTime";

export const parseFormData = (
  data: FormInterviewData,
  calendars: NylasCalendar[],
) => {
  const calendar = calendars.find(item => item.calendar_id === data.calendar)!;

  return {
    stageId: data.stageId,
    calendarId: calendar.id,
    createConference: data.createConference,
    date: data.date,
    when: {
      endTime: adjustWhenTime(data.date, data.endTime, data.timezone),
      startTime: adjustWhenTime(data.date, data.startTime, data.timezone),
      timezone: data.timezone,
    },
    location: data.location || undefined,
    details: data.notes || undefined,
    interviewers:
      data.interviewTeam.map(teamMember => Number(teamMember)) || undefined,
  };
};
