import {
  InterviewEvent,
  InterviewEventWithStage,
} from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview.types";

export enum InterviewEventCardViewMode {
  NoTitle = "noTitle",
  Default = "default",
}

export interface InterviewEventCardProps {
  interview: InterviewEvent | InterviewEventWithStage;
  className?: string;
  viewMode?: InterviewEventCardViewMode;
}
