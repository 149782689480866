import { Application } from "~/scalis-components/pipeline/detailed-candidate-view";
import { cn } from "~/utils/cn";
import { MILESTONE_COLOR } from "~/utils/milestoneColors";

interface StagePillProps {
  stage: Application["stage"];
  className?: string;
}

export const StagePill: React.FC<StagePillProps> = ({ stage, className }) => {
  return (
    <span
      className={cn(
        "flex h-6 items-center justify-center rounded-full px-3 text-sm",
        className,
      )}
      style={{
        backgroundColor: stage?.milestone?.milestoneType
          ? MILESTONE_COLOR[stage.milestone.milestoneType]
          : "white",
      }}
    >
      {stage?.name ?? "No Stage"}
    </span>
  );
};
