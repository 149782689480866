import {
  DegreeLevel,
  ExpRemote,
  Gender,
  HowSoonStart,
  JobLevel,
  JobRemote,
  JobType,
  NonTraditional,
  Role,
  TryingToRelocate,
  WillingToRelocate,
} from "@prisma/client";

// re-export
export {
  DegreeLevel,
  ExpRemote,
  Gender,
  HowSoonStart,
  JobLevel,
  JobRemote,
  JobType,
  NonTraditional,
  Role,
  TryingToRelocate,
  WillingToRelocate,
};

export type raceEthnicity =
  | "white"
  | "black_or_african_amercian"
  | "native_hawaiian_or_pacific_islander"
  | "native_american_or_alaska_native"
  | "middle_eastern"
  | "asian"
  | "hispani_or_latino"
  | "other_race"
  | "prefer_not_to_answer";

export type jobSeekerBasicInfo = {
  id: number;
  seekerId: number;
  f_name?: string;
  l_name?: string;
  position?: string;
  role: Role;
  email: string;
  password: string;
  password_reset_code?: string;
  phone_number?: string;
  is_email_verified: boolean;
  is_phone_verified: boolean;
  email_verification_code?: string;
  website?: string;
  address_line_1?: string;
  address_line_2?: string;
  state?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  is_student?: string;
  year_graduate?: number;
  workforce_years?: number;
  full_time_exp?: number;
  job_type?: JobType;
  current_role?: any;
  level?: JobLevel;
  message_user_id?: string;
  comfortable_leading_min?: number;
  comfortable_leading_max?: number;
  current_stage?: string;
  hourly_salary?: string;
  min_amount?: number;
  max_amount?: number;
  hourly_min_amount?: number;
  hourly_max_amount?: number;
  willing_to_relocate?: WillingToRelocate;
  willing_to_relocate_locations?: any;
  trying_to_relocate?: TryingToRelocate;
  remote_or_inperson?: JobRemote;
  how_soon_start?: HowSoonStart;
  non_traditional?: NonTraditional;
  race_ethinicity?: Array<raceEthnicity> | null;
  gender?: Gender;
  veteran_status?: "YES" | "NO" | "PREFER_NOT_TO_ANSWER" | null;
  token?: string;
  searching_jobs?: boolean;
  profile_image?: string;
  profile_image_id?: string;
  profile_link?: string;
  profile_public?: string;
  linkedin_link?: string;
  twitter_link?: string;
  facebook_link?: string;
  website_link?: string;
  website_b_link?: string;
  short_description?: string;
  search_short_description?: string;
  createdAt: string;
  updatedAt: string;
};

export type seekerProfileHeader = {
  id: number;
  seekerId: number;
  job_title?: string;
  education?: string;
  first_name?: string;
  last_name?: string;
  headline?: string;
  searching_jobs?: boolean;
  createdAt: string | Date;
  updatedAt: string | Date;
};

export type curricular = {
  id: number;
  seekerId: number;
  company_institute_name?: string;
  job_title?: string;
  job_description?: string;
  search_job_description?: string;
  is_currently_working?: "YES" | "NO";
  start_year: number;
  end_year?: number;
  start_month: number;
  end_month?: number;
  job_type?: JobType;
  location?: string;
  createdAt: string;
  updatedAt: string;
};

export type achievement = {
  id: number;
  seekerId: number;
  award_name?: string;
  issuing_authority?: string;
  year_issued?: number;
  description?: string;
  search_description?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type certificate = {
  id: number;
  seekerId: number;
  certificate_license?: string;
  issuing_authority?: string;
  year_issued?: number;
  description?: string;
  search_description?: string;
  createdAt: string;
  updatedAt: string;
};

export type education = {
  id: number;
  seekerId: number;
  school_training_institute: string;
  currently_studying?: "YES" | "NO";
  degree?: DegreeLevel;
  major?: any;
  minor?: any;
  department_gpa?: number;
  cumulative_gpa?: number;
  department_gpa_scale?: number;
  cumulative_gpa_scale?: number;
  dept_gpa_hide?: boolean;
  cumul_gpa_hide?: boolean;
  area_of_study?: any;
  start_year: number;
  end_year?: number | null;
  start_month: number;
  end_month?: number;
  createdAt: string | Date;
  updatedAt: string | Date;
};

export type jobExperience = {
  id: number;
  seekerId: number;
  employer?: string;
  is_currently_working?: "YES" | "NO" | null;
  job_title?: string;
  s_job_description?: string;
  search_s_job_description?: string;
  search_l_job_description?: string;
  l_job_description?: string;
  start_year: number;
  end_year?: number;
  start_month: number;
  end_month?: number;
  management_position?: boolean;
  salary_wage?: number;
  remote_or_inperson?: ExpRemote;
  how_large_team_min?: number | null;
  how_large_team_max?: number | null;
  field_industry?: string;
  field_sub_industry?: string;
  job_type?: JobType;
  location?: string;
  hourly_salary?: "HOURLY" | "SALARY" | "" | null;
  salary?: number | null;
  plus_comission?: boolean | null;
  createdAt: string | Date;
  updatedAt: string | Date;
};

export type skill = {
  id: number;
  seekerId: number;
  skill_name?: string;
  year_of_experience?: number;
  skill_level?: string;
  createdAt: string;
  updatedAt: string;
};

type seekerReference = {
  id: number;
  seekerId: number;
  f_name: string;
  l_name: string;
  email: string;
  city?: string;
  state?: string;
  current_company?: string;
  current_title?: string;
  relation?: string;
  reference?: string;
  skills?: string;
  createdAt: string;
  updatedAt: string;
};

export type jobSeekerAccount = {
  id: number;
  role: Role;
  ip_address?: string;
  email: string;
  password: string;
  password_reset_code?: string;
  is_email_verified: boolean;
  email_verification_code?: string;
  token?: string;
  match_to_other_jid?: any;
  createdAt: string;
  updatedAt: string;
  job_seeker_basicinfo?: jobSeekerBasicInfo;
  seeker_profile_header?: seekerProfileHeader;
  curricular: curricular[];
  achievements: achievement[];
  certificate: certificate[];
  educations: education[];
  jobExpericences: jobExperience[];
  skills: skill[];
  seeker_reference: seekerReference[];
  deleted: boolean;
  active: boolean;
};

export type VolunteerExperience = {
  company: string;
  job_title: string;
  job_description: string;
  search_job_description: string;
  start_year: number;
  end_year: number;
  start_month: number;
  end_month: number;
  currently_working: boolean;
};

export interface JobSeekerLocation {
  jobseeker_state: string;
  jobseeker_city: string;
}

export interface RaceEthnicity {
  [key: string]: string;
}

// TODO: reconcile with JobSeekerBasicInfo
export interface JobSeekerBasicInfo2 {
  address_line_1?: string;
  address_line_2?: string;
  year_graduate?: string;
  level?: JobLevel;
  job_seeker_locations: JobSeekerLocation[];
  hourly_salary?: string;
  min_amount?: string;
  max_amount?: string;
  remote_or_inperson?: JobRemote;
  current_role?: string[];
  how_soon_start?: HowSoonStart;
  latitude?: number;
  comfortable_leading_min?: string;
  comfortable_leading_max?: string;
  workforce_years?: string;
  full_time_exp?: string;
  job_type?: JobType;
  longitude?: number;
  phone_number?: string;
  state?: string;
  city?: string;
  zipcode?: string;
  current_stage?: string;
  willing_to_relocate?: WillingToRelocate;
  trying_to_relocate?: TryingToRelocate;
  is_student?: boolean;
}

// TODO: reconcile with Education
export type Education2 = {
  school_training_institute: string | null;
  degree?: DegreeLevel;
  area_of_study: any | null;
  major: any | null;
  minor: any | null;
  department_gpa: number | null;
  cumulative_gpa: number | null;
  cumul_gpa_hide: boolean;
  dept_gpa_hide: boolean;
  currently_studying: string | null;
  start_year: number | null;
  end_year: number | null;
  start_month: number | null;
  end_month: number | null;
};

export enum CandidateType {
  GUEST = "GUEST",
  JOBSEEKER = "JOBSEEKER",
  UPLOADED_CANDIDATE = "UPLOADED_CANDIDATE",
}
