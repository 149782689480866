import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import {
  CompleteInterviewProps,
  completeInterview,
} from "~/mutations/interviews/completeInterview";
import { deleteInterview } from "~/mutations/interviews/deleteInterview";
import {
  EditInterviewsProps,
  editInterviews,
} from "~/mutations/interviews/editInterviews";
import {
  ScheduleInterviewsProps,
  scheduleInterviews,
} from "~/mutations/interviews/scheduleInterviews";
import { DeleteInterviewQuerySchema } from "~/types/interviews/delete";

const useScheduleInterviews = (
  options: UseMutationOptions<any, unknown, ScheduleInterviewsProps, unknown>,
) => useMutation({ mutationFn: scheduleInterviews, ...options });

const useEditInterviews = (
  options: UseMutationOptions<any, unknown, EditInterviewsProps, unknown>,
) => useMutation({ mutationFn: editInterviews, ...options });

const useCompleteInterview = (
  options: UseMutationOptions<any, unknown, CompleteInterviewProps, unknown>,
) => useMutation({ mutationFn: completeInterview, ...options });

const useDeleteInterview = (
  options: UseMutationOptions<any, unknown, DeleteInterviewQuerySchema, unknown>,
) => useMutation({ mutationFn: deleteInterview, ...options });

export {
  useCompleteInterview,
  useDeleteInterview,
  useEditInterviews,
  useScheduleInterviews,
};
