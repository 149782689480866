import { z } from "zod";

import { validateTimePast } from "~/utils/calendar/validateTimePast";
import { errorMessage } from "~/utils/errorMessage";

export const InterviewSchema = z.object({
  id: z.number(),
  interviewId: z.number(),
  stageId: z.number(),
  calendar: z.string({ required_error: errorMessage("calendar") }),
  date: z.date().refine(value => {
    return validateTimePast(value);
  }, "Date must be in the future or today"),
  startTime: z.string(),
  endTime: z.string(),
  timezone: z.string().min(1, errorMessage("timezone")),
  location: z.string().optional(),
  createConference: z.boolean(),
  interviewTeam: z.array(z.string()).min(1, errorMessage("interviewTeam")),
  notes: z.string().optional(),
});

export const EditInterviewFormSchema = z.object({
  interviews: z.array(InterviewSchema),
});
