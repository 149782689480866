import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

import { Button } from "~/components/v2/Button";
import { StagePill } from "~/components/v2/stage-pill";
import { InterviewEventWithStage } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview";
import {
  InterviewEventCard,
  InterviewEventCardViewMode,
} from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/interview-event-card";
import { cn } from "~/utils/cn";
import { MILESTONE_INTERVIEW_COLOR } from "./view-interview-card.utils";

interface ViewInterviewCardProps {
  interview: InterviewEventWithStage;
  open?: boolean;
}

export const ViewInterviewCard = ({
  interview,
  open,
}: ViewInterviewCardProps) => {
  const [collapsed, setCollapsed] = useState(open);

  const { stage, ...interviewWithoutStage } = interview;

  const handleToogleCollapse = () => setCollapsed(!collapsed);

  return (
    <div className={"relative flex flex-col rounded-xl border border-borders"}>
      {collapsed && (
        <div
          className={cn(
            "absolute bottom-0 left-0 top-0 z-10 w-2 rounded-l-lg",
            `${MILESTONE_INTERVIEW_COLOR[stage?.milestone?.milestoneType!]}`,
          )}
        />
      )}
      <div className="flex w-full items-center justify-between px-3 py-1.5">
        <StagePill stage={stage} />
        <div>
          <Button
            variant="secondary-ghost"
            size="icon"
            type="button"
            onClick={handleToogleCollapse}
          >
            {collapsed ? (
              <FaChevronUp className="text-base" />
            ) : (
              <FaChevronDown className="text-base" />
            )}
          </Button>
        </div>
      </div>
      {collapsed && (
        <div className="flex h-full w-full flex-col gap-4 border-t">
          <InterviewEventCard
            interview={interviewWithoutStage}
            viewMode={InterviewEventCardViewMode.NoTitle}
          />
        </div>
      )}
    </div>
  );
};
