import { useContext } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/v2/Avatar";
import { useGetAvatar } from "~/hooks/useGetAvatar";
import { cn } from "~/utils/cn";
import { getInitials } from "~/utils/getInitials";
import { GuestSelectContext } from "../../../guest-select-context";
import { CandidateOptionalType } from "../../../guest-select-context.types";
import { AvatarType } from "~/queries/fetchAvatar";

interface SuggestionItemProps {
  suggestion: CandidateOptionalType;
  itemIndex: number;
  isActive: boolean;
  onActive: (index: number) => void;
}

export const SuggestionItem = ({
  suggestion,
  itemIndex,
  isActive,
  onActive,
}: SuggestionItemProps) => {
  const { addSelected } = useContext(GuestSelectContext);
  const { data: avatar } = useGetAvatar(suggestion?.id, AvatarType.jobSeeker);

  const guestInitials = getInitials(
    suggestion?.name
      ? suggestion.name.toUpperCase()
      : suggestion.email.toUpperCase(),
  );

  return (
    <li
      key={suggestion.email}
      className={cn("cursor-pointer rounded-md p-2", {
        "bg-background-hover": isActive,
      })}
      onMouseEnter={() => onActive(itemIndex)}
      onClick={() => addSelected(suggestion)}
    >
      <div className="flex gap-2">
        <Avatar className="size-8">
          <AvatarImage src={avatar ?? ""} alt={suggestion?.name} />
          <AvatarFallback className="text-xs">{guestInitials}</AvatarFallback>
        </Avatar>
        <div className="flex flex-col justify-center">
          <span className="text-sm font-semibold text-typography-high-contrast">
            {suggestion?.name}
          </span>
          <span className="text-xs font-normal text-typography-low-contrast-text">
            {suggestion.email}
          </span>
        </div>
      </div>
    </li>
  );
};
