import { zodResolver } from "@hookform/resolvers/zod";
import { addHours, format } from "date-fns";
import { useContext, useEffect, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa6";
import * as R from "remeda";
import { z } from "zod";

import { Button } from "~/components/v2/Button";
import { Form } from "~/components/v2/Form";
import { InterviewCard } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/components/interview-card/interview-card";
import { ScheduleInterviewFormSchema } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/components/schedule-interview-form/schedule-interview-form.schema";
import { ScheduleInterviewFormProps } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/components/schedule-interview-form/schedule-interview-form.types";
import { ScheduleInterviewDrawerContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/schedule-interview-drawer-context";

export const ScheduleInterviewForm = ({
  isSubmit,
  onSubmit,
  onErrorSubmit,
}: ScheduleInterviewFormProps) => {
  const {
    defaultCalendarId,
    defaultTimezone,
    stages,
    initialStageId,
    interviews,
    interviewers,
  } = useContext(ScheduleInterviewDrawerContext);

  const form = useForm<z.infer<typeof ScheduleInterviewFormSchema>>({
    resolver: zodResolver(ScheduleInterviewFormSchema),
    defaultValues: {
      interviews: interviews
        ? interviews.map(interview => R.omit(interview, ["stageId"]))
        : [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "interviews",
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  const getInitialStageId = () => {
    const stageIds = form
      .watch("interviews")
      .map((interview: any) => Number(interview.stageId));

    const availableStages = stages.filter(
      stage => !stageIds.includes(stage!.id),
    );

    if (
      initialStageId &&
      availableStages.some(stage => stage!.id === initialStageId)
    )
      return initialStageId;

    return availableStages[0]!.id;
  };

  const handleAddInterview = () => {
    append({
      stageId: getInitialStageId(),
      calendar: defaultCalendarId ?? "",
      date: new Date(),
      startTime: format(addHours(new Date(), 1), "HH:00"),
      endTime: format(addHours(new Date(), 2), "HH:00"),
      timezone: defaultTimezone ?? "",
      location: "",
      createConference: false,
      interviewTeam: interviewers?.map(interviewer => interviewer.value) || [],
      notes: "",
    });
  };

  const handleRemoveInterview = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    if (isSubmit) {
      formRef?.current?.requestSubmit();
    }
  }, [isSubmit]);

  return (
    <Form {...form}>
      <form
        ref={formRef}
        onSubmit={form.handleSubmit(onSubmit!, onErrorSubmit)}
        className="flex flex-col gap-2"
      >
        {fields.map((item, index) => (
          <InterviewCard
            key={item.id}
            index={index}
            onDelete={handleRemoveInterview}
          />
        ))}

        <Button
          variant="ghost"
          data-testid="add-interview"
          className="flex w-40 text-typography-color"
          type="button"
          disabled={stages?.length === form.watch("interviews").length}
          onClick={handleAddInterview}
        >
          Add interview
          <FaPlus className="ml-2" />
        </Button>
      </form>
    </Form>
  );
};
