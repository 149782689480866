import axios from "~/services/axios.service";
import { DeleteInterviewQuerySchema } from "~/types/interviews/delete";

export const deleteInterview = async ({
  eventId,
  applicationId,
  stageId,
}: DeleteInterviewQuerySchema): Promise<{ completed: boolean }> =>
  await axios
    .delete(`/api/interviews/delete/${eventId}`, {
      params: { applicationId, stageId },
    })
    .then(response => response.data)
    .catch(e => e.message);
