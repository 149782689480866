import { ProfilePicture } from "~/components/v2/ProfilePicture";
import { useGetAvatar } from "~/hooks/useGetAvatar";
import { AvatarType } from "~/queries/fetchAvatar";

interface InterviewerItemProps {
  interviewer: {
    id?: number;
    name?: string;
    email: string;
  };
}

export const InterviewerItem = ({ interviewer }: InterviewerItemProps) => {
  const { data: avatar } = useGetAvatar(interviewer.id, AvatarType.companyUser);

  return (
    <div className="flex items-center gap-2 mt-2">
      <ProfilePicture src={avatar} fullName={interviewer.name} size="small" />
      <div className="flex flex-col">
        {interviewer.name && (
          <span className="text-sm text-typography-high-contrast">
            {interviewer.name}
          </span>
        )}
      </div>
    </div>
  );
};
