import axios from "~/services/axios.service";
import { InterviewsBody } from "~/types/interviews";

export interface EditInterviewsProps {
  applicationId: number;
  data: InterviewsBody;
}

export const editInterviews = async ({
  applicationId,
  data,
}: EditInterviewsProps): Promise<{ completed: boolean }> =>
  axios
    .put(`/api/interviews/${applicationId}`, data)
    .then(response => response.data)
    .catch(e => e.message);
