import axios from "~/services/axios.service";
import { UserWithNylasAccount } from "~/types/calendar/nylasProviders";

export const fetchUser = async (
  email: string,
): Promise<UserWithNylasAccount> => {
  const response = await axios.get("/api/user", {
    params: {
      email,
    },
  });

  return response.data;
};
