import { format, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const adjustWhenTime = (
  date: Date,
  time: string,
  timezone: string,
): string => {
  const utcDate = zonedTimeToUtc(
    parseISO(`${format(date, "yyyy-MM-dd")}T${time}:00`),
    timezone,
  );
  return utcDate.toISOString();
};

export default adjustWhenTime;
