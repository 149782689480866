import { useMemo } from "react";

import { formatDateInterview } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/interview-header/interview-header.utils";
import { InterviewHeaderProps } from "./interview-header.types";

export const InterviewHeader = ({ interviews }: InterviewHeaderProps) => {
  const interviewDates = useMemo(
    () =>
      interviews
        ?.map(interview => {
          return {
            key: interview.id,
            date: formatDateInterview(new Date(interview.startTime)),
            timestamp: new Date(interview.startTime).getTime(),
          };
        })
        .sort((a, b) => a.timestamp - b.timestamp) ?? [],
    [interviews],
  );

  return (
    <div className="space-y-2 text-typography-high-contrast">
      <span className="text-base font-semibold">Schedule Interview</span>
      {interviewDates?.length > 0 && (
        <ul className="flex flex-col text-sm">
          {interviewDates?.map(d => <li key={d.key}>{d.date}</li>)}
        </ul>
      )}
    </div>
  );
};
