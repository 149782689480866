import { PlusIcon } from "@radix-ui/react-icons";
import { useContext } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/v2/Avatar";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { useGetAvatar } from "~/hooks/useGetAvatar";
import { getInitials } from "~/utils/getInitials";
import { GuestSelectContext } from "../../../guest-select-context";
import { CandidateOptionalType } from "../../../guest-select-context.types";
import { AvatarType } from "~/queries/fetchAvatar";

interface SelectedItemProps {
  item: CandidateOptionalType;
}

export const SelectedItem = ({ item }: SelectedItemProps) => {
  const { removeSelected } = useContext(GuestSelectContext);
  const { data: avatar } = useGetAvatar(item?.id, AvatarType.jobSeeker);

  const avatarName = item?.name ? `${item.name} (SCALIS User)` : "";

  return (
    <div key={item.email} className="flex items-center justify-between">
      <div className="flex gap-2">
        <Avatar className="h-5 w-5">
          <AvatarImage src={avatar ?? ""} alt={item?.name} />
          <AvatarFallback className="text-xs">
            {getInitials(item.email)}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col justify-center">
          <span className="text-sm font-normal text-typography-high-contrast">
            {avatarName}
          </span>
          <span className="text-xs font-normal text-typography-low-contrast-text">
            {item.email}
          </span>
        </div>
      </div>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <button
              className="focus-visible:ring-ring inline-flex items-center justify-center rounded-full text-sm font-medium text-actions-secondary transition-colors hover:bg-background-hover focus-visible:outline-none focus-visible:ring-1 active:bg-background-pressed disabled:pointer-events-none disabled:bg-background-muted disabled:text-actions-muted"
              onClick={() => removeSelected(item)}
            >
              <PlusIcon className="size-5 rotate-45" />
            </button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="z-50">Remove</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
