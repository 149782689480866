import { createContext, useEffect, useState } from "react";

import {
  EditInterviewDrawerContextProps,
  EditInterviewDrawerProviderProps,
} from "./edit-interview-drawer-context.types";

export const EditInterviewDrawerContext = createContext(
  {} as EditInterviewDrawerContextProps,
);

export const EditInterviewDrawerProvider = ({
  userId,
  stages,
  interviewers,
  initialInterviews,
  initialStageId,
  calendars,
  defaultTimezone,
  children,
}: EditInterviewDrawerProviderProps) => {
  const [defaultCalendarId, setDefaultCalendarId] = useState<string>();

  useEffect(() => {
    if (calendars && calendars.length > 0) {
      const primaryCalendar = calendars.find(c => c.is_primary);

      if (primaryCalendar) setDefaultCalendarId(primaryCalendar?.calendar_id);
      else setDefaultCalendarId(calendars[0].calendar_id);
    }
  }, [calendars]);

  return (
    <EditInterviewDrawerContext.Provider
      value={{
        userId,
        stages,
        interviews: initialInterviews,
        initialStageId,
        interviewers,
        calendars,
        defaultCalendarId,
        defaultTimezone,
      }}
    >
      {children}
    </EditInterviewDrawerContext.Provider>
  );
};
