import axios from "~/services/axios.service";
import { UpdateCompleteInterviewSchema } from "~/types/interviews/complete";

export interface CompleteInterviewProps {
  eventId: number;
  data: UpdateCompleteInterviewSchema;
}

export const completeInterview = async ({
  eventId,
  data,
}: CompleteInterviewProps): Promise<{ completed: boolean }> =>
  await axios
    .put(`/api/interviews/complete/${eventId}`, data)
    .then(response => response.data)
    .catch(e => e.message);
