import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useContext, useState } from "react";

import { Button } from "~/components/v2/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { useEditInterviews } from "~/hooks/interviews/useScheduleInterviews";
import { useToast } from "~/hooks/useToast";
import { InterviewsBody } from "~/types/interviews";
import { FormData } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/edit-interview-drawer/components/edit-interview-form/edit-interview-form.types";
import { EditInterviewDrawerProvider } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/edit-interview-drawer/edit-interview-drawer-context";
import { parseFormData } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/edit-interview-drawer/edit-interview-drawer.utils";
import { ScheduleInterviewContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview-context";
import { errorHandler } from "~/utils/error";
import { EditInterviewForm } from "./components/edit-interview-form";
import { EditInterviewHeader } from "./components/edit-interview-header";
import { EditInterviewDrawerProps } from "./edit-interview-drawer.types";

export const EditInterviewDrawer = ({
  userId,
  stages,
  initialInterviews,
  open,
  onOpenChange,
}: EditInterviewDrawerProps) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { applicationId, stageId, interviewers, calendars, defaultTimezone } =
    useContext(ScheduleInterviewContext);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { mutateAsync: updateInterviews, isPending: isLoading } =
    useEditInterviews({
      onSuccess: () => {
        onOpenChange(false);
        setIsSubmit(false);
        queryClient.invalidateQueries({
          queryKey: ["candidate-application", applicationId],
        });
      },
      onError: e => {
        errorHandler(toast, e as AxiosError<any>);
      },
    });

  const handleSubmit = async (data: FormData) => {
    const parsedInterviews = data.interviews
      .map(interviewForm => {
        return parseFormData(interviewForm, calendars);
      })
      .filter(i => i) as InterviewsBody;

    if (!parsedInterviews?.length) return;

    await updateInterviews({
      applicationId,
      data: [...parsedInterviews],
    });
  };

  const handleErrorSubmit = (error: any) => {
    setIsSubmit(false);
  };

  return (
    <EditInterviewDrawerProvider
      userId={userId}
      stages={stages}
      interviewers={interviewers!}
      initialInterviews={initialInterviews}
      initialStageId={stageId}
      calendars={calendars}
      defaultTimezone={defaultTimezone}
    >
      <Drawer open={open} onOpenChange={onOpenChange}>
        <DrawerContent asChild onResize={() => false}>
          <div>
            <DrawerHeader className="text-center">
              <h1 className="text-base font-semibold text-typography-high-contrast">
                Edit Interview
              </h1>
            </DrawerHeader>

            <div className="h-full p-4 space-y-2 overflow-y-auto">
              <EditInterviewHeader />

              <EditInterviewForm
                isSubmit={isSubmit}
                onSubmit={(data: FormData) => {
                  handleSubmit(data);
                }}
                onErrorSubmit={handleErrorSubmit}
              />
            </div>

            <DrawerFooter className="justify-end">
              <DrawerClose asChild>
                <Button variant="outline">Back</Button>
              </DrawerClose>
              <Button
                aria-label="Confirm"
                className="min-w-20"
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => setIsSubmit(true)}
              >
                Save
              </Button>
            </DrawerFooter>
          </div>
        </DrawerContent>
      </Drawer>
    </EditInterviewDrawerProvider>
  );
};
