import { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/v2/DropdownMenu";
import { StagePill } from "~/components/v2/stage-pill";
import { StageSelectProps } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/components/interview-card/components/stage-select/stage-select.types";
import { StageData } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/schedule-interview-drawer.types";

export const StageSelect = ({
  initialStage,
  stages,
  onSelect,
}: StageSelectProps) => {
  const [open, setOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState<StageData>(
    initialStage ?? stages[0],
  );

  const handleSelect = (stage: StageData) => {
    onSelect(stage);
    setSelectedStage(stage);
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <div
          className="flex h-6 items-center gap-1"
          data-testid="stage-select-trigger"
        >
          <StagePill stage={selectedStage} />
          <button
            type="button"
            className="flex size-6 items-center justify-center rounded-full hover:bg-background-hover hover:opacity-85"
          >
            {open ? (
              <FaCaretUp className="text-base text-typography-low-contrast" />
            ) : (
              <FaCaretDown className="text-base text-typography-low-contrast" />
            )}
          </button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="rounded bg-white p-0 shadow-md">
        <DropdownMenuGroup>
          {stages.map(stageItem => {
            return (
              <DropdownMenuItem
                key={stageItem?.id}
                className="cursor-pointer hover:bg-background-hover"
                onSelect={() => handleSelect(stageItem)}
              >
                <StagePill stage={stageItem} className="w-full text-center" />
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
