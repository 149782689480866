import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useRef } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import { Form } from "~/components/v2/Form";
import { EditInterviewCard } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/edit-interview-drawer/components/edit-interview-card";
import { ViewInterviewCard } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/edit-interview-drawer/components/view-interview-card";
import { EditInterviewDrawerContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/edit-interview-drawer/edit-interview-drawer-context";
import { EditInterviewFormSchema } from "./edit-interview-form.schema";
import { EditInterviewFormProps } from "./edit-interview-form.types";
import { convertTo24HourFormat } from "./edit-interview-form.utils";

export const EditInterviewForm = ({
  isSubmit,
  onSubmit,
  onErrorSubmit,
}: EditInterviewFormProps) => {
  const { calendars, interviews, initialStageId, userId } = useContext(
    EditInterviewDrawerContext,
  );

  const canEdit = (calendarId: string, interviewId: number) => {
    const interview = interviews.find(i => i.interviewId === interviewId);

    const hasCalendar = calendars.some(c => c.calendar_id === calendarId);
    const isIterviewAvailable = interview?.status !== "INTERVIEW_COMPLETED";

    return hasCalendar && isIterviewAvailable;
  };

  const form = useForm<z.infer<typeof EditInterviewFormSchema>>({
    resolver: zodResolver(EditInterviewFormSchema),
    defaultValues: {
      interviews: interviews
        ? interviews
            .map(interview => {
              const formattedDate = {
                ...interview,
                id: interview.id,
                interviewId: interview.interviewId,
                date: new Date(interview.startTime),
                stageId: interview.stage?.id || interview.interview?.stageId,
                calendar: interview.nylasCalendar.calendar_id,
                startTime: convertTo24HourFormat(interview.startTime),
                endTime: convertTo24HourFormat(interview.endTime),
                timezone: interview.timezone,
                createConference: !!interview?.conferencing?.details?.url,
                interviewTeam: interview.internalHrAssociates.map(hr =>
                  String(hr.id),
                ),
                location: interview.location || "",
                notes: interview.notes || "",
                conferencing: interview?.conferencing,
              };

              const hasForm = canEdit(
                formattedDate.calendar,
                formattedDate.interviewId,
              );

              if (hasForm) {
                return formattedDate;
              }
            })
            .filter(i => i !== undefined)
        : [],
    },
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "interviews",
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (isSubmit) {
      formRef?.current?.requestSubmit();
    }
  }, [isSubmit]);

  return (
    <Form {...form}>
      <form
        ref={formRef}
        onSubmit={form.handleSubmit(onSubmit!, onErrorSubmit)}
        className="flex flex-col gap-2"
      >
        {interviews.map((item, index) => {
          const fieldIndex = fields.findIndex(
            f => f.interviewId === item.interviewId,
          );

          const field = fields[fieldIndex];

          if (!field) {
            return (
              <ViewInterviewCard
                key={item.id}
                interview={interviews[index]}
                open={
                  initialStageId === item.stage?.id ||
                  initialStageId === item.interview?.stageId
                }
              />
            );
          } else {
            return (
              <EditInterviewCard
                key={field.id}
                index={fieldIndex}
                open={initialStageId === field.stageId}
                userId={userId}
              />
            );
          }
        })}
      </form>
    </Form>
  );
};
