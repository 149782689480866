import { createContext, useMemo } from "react";

import {
  ScheduleInterviewContextProps,
  ScheduleInterviewProviderProps,
} from "./schedule-interview-context.types";

export const ScheduleInterviewContext = createContext(
  {} as ScheduleInterviewContextProps,
);

export const ScheduleInterviewProvider = ({
  applicationId,
  stageId,
  calendars,
  candidates = [],
  interviewers,
  defaultTimezone,
  children,
}: ScheduleInterviewProviderProps) => {
  const defaultCalendarId = useMemo(() => {
    if (calendars && calendars.length > 0) {
      const primaryCalendar = calendars.find(c => c.is_primary);
      return primaryCalendar ? primaryCalendar.id : calendars[0].id;
    }
    return undefined;
  }, [calendars]);

  const valueMemoized = useMemo(() => {
    return {
      applicationId,
      stageId,
      calendars,
      candidates,
      interviewers,
      defaultTimezone,
      defaultCalendarId,
    };
  }, [
    applicationId,
    stageId,
    calendars,
    candidates,
    interviewers,
    defaultTimezone,
    defaultCalendarId,
  ]);

  return (
    <ScheduleInterviewContext.Provider value={valueMemoized}>
      {children}
    </ScheduleInterviewContext.Provider>
  );
};
