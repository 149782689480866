interface NoSuggestionsProps {
  message: string;
}

export const NoSuggestions = ({ message }: NoSuggestionsProps) => {
  if (!message) {
    return null;
  }

  return (
    <span className="text-center text-sm text-typography-low-contrast">
      {message}
    </span>
  );
};
