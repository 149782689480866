import { MilestoneType } from "@prisma/client";

export const MILESTONE_INTERVIEW_COLOR: { [key in MilestoneType]: string } = {
  SOURCING: "bg-extra-01",
  APPLICATION_REVIEW: "bg-extra-02",
  PRE_SCREEN: "bg-extra-03",
  CUSTOM: "bg-extra-04",
  HIRED: "bg-extra-05",
  OFFER: "bg-extra-06",
};
