import { useContext } from "react";

import { GuestSelectContext } from "../../guest-select-context";
import { SelectedItem } from "./components/selected-item";

export const GuestSelectListSelected = () => {
  const { selected } = useContext(GuestSelectContext);

  if (selected?.length === 0) {
    return null;
  }

  return (
    <div className="z-10 flex flex-col gap-2 mt-2 overflow-x-hidden overflow-y-auto max-h-56">
      {selected?.map(item => <SelectedItem key={item.email} item={item} />)}
    </div>
  );
};
