import { createContext, useEffect, useState } from "react";

import {
  ScheduleInterviewDrawerContextProps,
  ScheduleInterviewDrawerProviderProps,
} from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/schedule-interview-drawer/schedule-interview-drawer-context.types";

export const ScheduleInterviewDrawerContext = createContext(
  {} as ScheduleInterviewDrawerContextProps,
);

export const ScheduleInterviewDrawerProvider = ({
  stages,
  interviewers,
  initialStageId,
  calendars,
  defaultTimezone,
  children,
}: ScheduleInterviewDrawerProviderProps) => {
  const [defaultCalendarId, setDefaultCalendarId] = useState<string>();

  useEffect(() => {
    if (calendars && calendars.length > 0) {
      const primaryCalendar = calendars.find(c => c.is_primary);

      if (primaryCalendar) setDefaultCalendarId(primaryCalendar?.calendar_id);
      else setDefaultCalendarId(calendars[0].calendar_id);
    }
  }, [calendars]);

  return (
    <ScheduleInterviewDrawerContext.Provider
      value={{
        stages,
        interviews: [],
        initialStageId,
        interviewers,
        calendars,
        defaultCalendarId,
        defaultTimezone,
      }}
    >
      {children}
    </ScheduleInterviewDrawerContext.Provider>
  );
};
