import { KeyboardEvent, createContext, useMemo, useState } from "react";
import {
  CandidateOptionalType,
  GuestSelectContextProps,
  GuestSelectProviderProps,
} from "./guest-select-context.types";
import { validateEmail } from "./guest-select.utils";

export const GuestSelectContext = createContext({} as GuestSelectContextProps);

export const GuestSelectProvider = ({
  name,
  guests,
  selected,
  setSelected,
  children,
}: GuestSelectProviderProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const suggestions = useMemo(() => {
    return guests
      .filter(guest => !!guest.email)
      .filter(
        guest =>
          guest.email.toLowerCase().includes(searchValue.toLowerCase()) ||
          (guest.name &&
            guest.name.toLowerCase().includes(searchValue.toLowerCase())),
      );
  }, [guests, searchValue]);

  const addSelected = (addGuest: CandidateOptionalType) => {
    setSelected([...(selected || []), addGuest]);

    setSearchValue("");
  };

  const removeSelected = (removeGuest: CandidateOptionalType) => {
    setSelected(selected.filter(v => v.email !== removeGuest.email));
  };

  const showSuggestions = useMemo(
    () => searchValue.length > 3 && suggestions.length > 0,
    [searchValue, suggestions],
  );

  const showEmptyState = useMemo(
    () =>
      searchValue.length > 3 &&
      suggestions.length === 0 &&
      !validateEmail(searchValue),
    [searchValue, suggestions],
  );

  const showAddGuest = useMemo(
    () =>
      searchValue.length > 3 &&
      suggestions.length === 0 &&
      validateEmail(searchValue),
    [searchValue, suggestions],
  );

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const lastIndex = suggestions.length - 1;

    switch (e.key) {
      case "ArrowUp":
        if (activeIndex > 0) {
          setActiveIndex(prevIdx => prevIdx - 1);
        }
        break;
      case "ArrowDown":
        if (activeIndex < lastIndex) {
          setActiveIndex(prevIdx => prevIdx + 1);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (activeIndex >= 0 && activeIndex <= lastIndex) {
          const selectedOption = suggestions[activeIndex];

          if (selectedOption) {
            addSelected(selectedOption);
          }
        }
        break;
      default:
        break;
    }
  };

  return (
    <GuestSelectContext.Provider
      value={{
        guests,
        selected,
        suggestions,
        showSuggestions,
        showEmptyState,
        showAddGuest,
        searchValue,
        activeIndex,
        setSearchValue,
        setActiveIndex,
        addSelected,
        removeSelected,
        onKeyDown,
      }}
    >
      {children}
    </GuestSelectContext.Provider>
  );
};
