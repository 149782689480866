import { useContext } from "react";

import { EditInterviewDrawerContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/edit-interview-drawer/edit-interview-drawer-context";
import { InterviewHeader } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/interview-header";

export const EditInterviewHeader = () => {
  const { interviews } = useContext(EditInterviewDrawerContext);

  return <InterviewHeader interviews={interviews || []} />;
};
