import { GuestSelectContainer } from "./components/guest-select-container";
import { GuestSelectInput } from "./components/guest-select-input";
import { GuestSelectListSelected } from "./components/guest-select-list-selected";
import { GuestSelectListSuggestions } from "./components/guest-select-list-suggestions/guest-select-list-suggestions";
import { GuestSelectProvider } from "./guest-select-context";
import { CandidateOptionalType } from "./guest-select-context.types";

interface GuestSelectProps {
  name: string;
  options: CandidateOptionalType[];
  value: CandidateOptionalType[];
  emptyMessage: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (values: CandidateOptionalType[]) => void;
}

export const GuestSelect = ({
  name,
  options,
  value,
  disabled,
  placeholder = "Search a guest",
  emptyMessage,
  onChange,
}: GuestSelectProps) => {
  return (
    <GuestSelectProvider
      name={name}
      guests={options}
      selected={value}
      setSelected={onChange}
    >
      <GuestSelectContainer>
        <GuestSelectInput disabled={disabled} placeholder={placeholder} />
        <GuestSelectListSuggestions emptyMessage={emptyMessage} />
        <GuestSelectListSelected />
      </GuestSelectContainer>
    </GuestSelectProvider>
  );
};

GuestSelect.displayName = "GuestSelect";
